import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Clean Grip Deadlifts 4×6\\@70% Deadlift 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`8:00 EMOM of:`}</p>
    <p>{`10-SDHP’s (95/65)`}</p>
    <p>{`50ft Shuttle Run x 2 (RX+ 50ft x 4)`}</p>
    <p>{`then at 11:00,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`25-Calorie Row`}</p>
    <p>{`25-Wall Balls (20/14) (RX+ 30/20)`}</p>
    <p><em parentName="p">{`*`}{`compare to 8/16/18`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our Granite Games Winter Throwdown is December 7th here at The
Ville!  Another opportunity to earn a spot at the Granite Games
Championships next year.  The last day to register your team and get
your shirt on the day of the competition is November 13th so don’t wait,
sign up today!  We’re only taking 30 teams and we’ve sold out for both
Throwdowns so get your team registered!  `}</em></strong></p>
    <p><strong parentName="p">{`*`}{`*`}{`*`}{`only 2 spots remaining!`}{`*`}{`*`}{`*`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://thegranitegames.com/about-throwdowns/#locations"
      }}>{`https://thegranitegames.com/about-throwdowns/#locations`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      